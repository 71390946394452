<template>
  <div :class="'info_window container ' + company.slug">
    <div class="alert alert-secondary" role="alert">
<router-link to="/CompanyMap"><i class="fas fa-map-marked-alt"></i> zurück zur Karte</router-link>
</div>
   <h1>{{company.company}}</h1>
<hr />
            <div class="row">
              <div class="col-md-7">
                  <img style="float:left;margin:0 1em 1em 0;width:300px" v-if="company.files.filter(f => (f.endsWith('.jpg') || f.endsWith('.png')) && f!=company.logo).length>0" :src="'/companies/'+company.slug+'/'+company.files.filter(f => (f.endsWith('.jpg') || f.endsWith('.png')) && f!=company.logo)[0]" /> 
                 
                   <p v-html="company.teaser"></p>

      <p v-html="company.text"></p>

<hr class="firstSecond" />
                 

<div class="row">
  <div class="col-md-7 jobs">
<div v-if="company.jobtitles.length>0" class="card bg-light h-100" >
  <div class="card-header">Diese Berufe bilden wir aus</div>
  <div class="card-body">
    <p class="card-text">
      <ul>
        <li v-for="job in company.jobtitles">{{job}}</li>
      </ul>
    </p>
  </div>
</div>

</div>
  <div class="col-md-5 downloads">

<ul>
        <li v-for="file in company.files.filter(f => f.endsWith('.pdf'))"><a :href="'/companies/'+company.slug+'/'+file" target="_blank"><img width="30" src="https://www.gfe-media.de/blog/wp-content/uploads/2015/10/pdf-icon-1.png"> Steckbrief.pdf</a></li>
      </ul>


    <!--
<div v-if="company.files.filter(f => f.endsWith('.pdf')).length>0" class="card bg-light h-100">
  <div class="card-header">Downloads</div>
  <div class="card-body">
    <p class="card-text">
      <ul>
        <li v-for="file in company.files.filter(f => f.endsWith('.pdf'))"><a :href="'/companies/'+company.slug+'/'+file" target="_blank">Steckbrief.pdf</a></li>
      </ul>
    </p>
  </div>
 
</div> -->
</div>

</div>

          <div v-if="company.files.filter(f => f.endsWith('.mp4') || f.endsWith('.mov')).length>0">
                <video class="hide-in-ie8" controls="true" height="400" width="640" preload="auto"> 
                  <source v-for="file in company.files.filter(f => f.endsWith('.mp4') || f.endsWith('.mov'))" :src="'/companies/'+company.slug+'/'+file" /> 
                 
                </video> 
              </div>

        </div>




<div class="col-md-1">
  </div>



                <div class="col-md-4">
                  <div class="logo">
                              <img width="100%" class="thumbnail" :src="'/companies/'+company.slug+'/'+company.logo" />
                     </div>         <br />
                    <strong v-if="company.contact!=''">{{company.contact}}</strong>
                     <br />
              <p>{{company.street}}<br />{{company.zipCode}} {{company.city}}</p>
             <p v-if="company.website!=null && company.website.length>0">
               <a style="display:block" v-for="link in company.website" :href="link" target="_blank">{{link}}</a>
               
               </p>
<div class="video-container">
                 <iframe v-if="company.yt_link!=null"   :src="company.yt_link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
               
                </div>
            </div>




  </div>
</template>

<script>
import companies from '../json/companies.json'

export default {
  name: 'Company',
  components: {
   
  },
  props: ['slug'],
  data () {
    return {
      companies: companies
    }
  },
  computed: {
    company() {
      return companies.filter(c => c.slug == this.slug)[0];
    }
  },
  
}
</script>
